.app-header {
  width: 100%;
  padding: 1.5rem 0;
  position: relative;
  z-index: 10;
}
.eon-logo-wrap {
  flex: 1;
}
.eon-logo {
  width: 96px;
  height: 30px;
}
.nav-wrap {
  flex: 0.5;
  justify-content: flex-end;
}
.join-wrap {
  display: none;
}

.burger {
  justify-content: center;
  height: 53px;
  width: 53px;
  background-color: #0a2440;
  color: #ffffff;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
.burger svg {
  width: 25px;
  height: 25px;
}
.menu-wrap {
  position: relative;
}

@media (min-width: 1024px) {
  .join-wrap {
    display: block;
  }
  .eon-logo {
    width: 125px;
    height: 40px;
  }
  .burger {
    width: 63px;
    height: 63px;
  }
  .burger svg {
    width: 28px;
    height: 28px;
  }
  .nav-wrap {
    gap: 15px;
  }
}
@media (min-width: 1536px) {
  .join-wrap {
    display: block;
  }
  .eon-logo {
    width: 125px;
    height: 40px;
  }
  .burger {
    width: 63px;
    height: 63px;
  }
  .burger svg {
    width: 28px;
    height: 28px;
  }
  .nav-wrap {
    gap: 15px;
  }
}
