.join-btn {
  padding: 15px 20px;
  border: 1px solid #ffffff;
  border-radius: 70px;
  background: none;
  font-family: hoves-regular, sans-serif;
  font-size: 18px;
  color: #ffffff;
  gap: 10px;
  cursor: pointer;
  flex-wrap: nowrap;
  position: relative;
}
.join-btn span {
  text-shadow: 1px 2px 4px rgb(0 0 0 / 25%);
  white-space: nowrap;
  position: relative;
  z-index: 1;
  transition: color 0.4s linear;
}
.join-btn svg {
  z-index: 1;
  transform: rotate(-35deg);
  transition: all 0.3s ease-in-out;
}
.join-btn:hover {
  color: #0a2440;
}
.join-btn:hover span {
  text-shadow: none;
}
.join-btn:hover svg {
  transform: rotate(0deg);
}
