.container {
  width: auto;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
button {
  cursor: pointer;
}
.circle {
  width: 100%;
  height: 150%;
  position: absolute;
  border-radius: 50%;
  top: 100%;
}
.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #0a2440;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1120px;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
